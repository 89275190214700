import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import { NineNavigation } from "@9amhealth/wcl/generated/react";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TOP_NAV_HEIGHT } from "src/constants/layout";
import useSubscription from "src/lib/useSubscription";
import SignupCubit from "src/state/SignupCubit/SignupCubit";
import { PayerId } from "src/state/SubscriptionCubit/SubscriptionCubit";
import type {
  SignupCustomPageParameters,
  SignupCustomStep
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";

const Table = styled.table`
  border: 1px solid black;
  margin: 2rem auto 6rem;
  max-width: calc(100vw - 2em);

  th {
    border: 1px solid black;
    padding: 0.5rem;
    background: #eee;
  }
  td {
    border: 1px solid black;
    padding: 0.5rem;

    a {
      text-decoration: underline;
    }
  }
`;

const PageWrap = styled.div`
  padding-top: ${TOP_NAV_HEIGHT};
  height: 100vh;
  overflow: auto;

  nine-center {
    display: contents;
  }
`;

const CheckDataCompleted: FC<{ step: SignupCustomStep }> = ({ step }) => {
  const [completed, setCompleted] = useState<string>("⏳");

  useEffect(() => {
    if (step.dataComplete && typeof step.dataComplete === "function") {
      const c = step.dataComplete();
      if (typeof c === "boolean") {
        setCompleted(c ? "✅" : "❌");
      } else if (c instanceof Promise) {
        c.then((d) => {
          setCompleted(d ? "✅" : "❌");
        }).catch(() => {
          setCompleted("⛔️");
        });
      }
    } else {
      setCompleted("🤞");
    }
  }, []);

  return <b>{completed}</b>;
};

const SignupCustomPageDebug: FC = () => {
  useEffect(() => {
    SignupCubit.loadSupportChat();
    return SignupCubit.removeSupportChat;
  }, []);

  const parameters = useParams<SignupCustomPageParameters>();
  const sub = useSubscription({
    status: [
      SubscriptionDetailsResponse.status.DRAFT,
      SubscriptionDetailsResponse.status.ACTIVE
    ],
    metadataPartnerFunnel: [parameters.campaign ?? ""]
  });
  const [
    state,
    { coBrandedLogo, campaignSteps, customCampaigns, campaignPrettyName }
  ] = useBloc(SignupCustomBloc, {
    props: {
      parameters: {
        campaign: parameters.campaign,
        step: undefined
      },
      options: {
        preview: true
      }
    }
  });

  const partnerItem = sub?.purchaseItems.find(
    (item) => item.metadata["partner.funnel"] === parameters.campaign
  );

  const payer = partnerItem?.metadata["partner.payer"] as string | undefined;

  const subDetails = {
    totalPrice: sub?.totalPrice,
    coveredBy: payer ? String(payer) : null,
    cashPay: payer === PayerId.CASH_PAY ? "Yes" : "No"
  };

  return (
    <PageWrap>
      <NineNavigation
        app
        variant="stepper"
        style={{ background: `var(--color-cream)` }}
        partnerLogo={coBrandedLogo ? coBrandedLogo + "?mock-type=.png" : ""}
      >
        <nine-overlay slot="help" variant="toast">
          <div slot="trigger" className="strong">
            Help?
          </div>
          <div slot="content">
            <h5 className="strong m0">Questions?</h5>
            <p className="lauf-text">
              Text or call us at <a href="tel:2029329958">(202) 932-9958</a>
              <br />
              or email us anytime at{" "}
              <a href="mailto:support@join9am.com">support@join9am.com</a>
            </p>
          </div>
        </nine-overlay>
      </NineNavigation>

      <nine-spacer s="xxl"></nine-spacer>
      <nine-center>
        <h4>Campaign: {campaignPrettyName}</h4>
      </nine-center>
      {sub && (
        <Table>
          <thead>
            <tr>
              <th>Subscription Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TotalPrice</td>
              <td>{subDetails.totalPrice}</td>
            </tr>

            <tr>
              <td>CoveredBy</td>
              <td>{subDetails.coveredBy}</td>
            </tr>

            <tr>
              <td>CashPay</td>
              <td>{subDetails.cashPay}</td>
            </tr>
          </tbody>
        </Table>
      )}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Path</th>
            <th>Completed</th>
          </tr>
        </thead>
        <tbody>
          {campaignSteps?.map((step, i) => (
            <tr key={`${i}`}>
              <td>{step.name}</td>
              <td>{step.type}</td>
              <td>
                <a
                  href={`/signup/${state.campaign}/${step.path}?preview=true`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {step.path}
                </a>
              </td>
              <td>
                <CheckDataCompleted step={step} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <nine-center>
        <h4 style={{ margin: 0 }}>Custom Campaigns</h4>
      </nine-center>
      <Table>
        <thead>
          <tr>
            <th>Funnel Name</th>
            <th>Funnel Path</th>
          </tr>
        </thead>
        <tbody>
          {customCampaigns.map((campaign, i) => {
            return (
              <tr key={`${i}`}>
                <td>{campaign.prettyName}</td>
                <td>
                  <a href={`/debug/signup/${campaign.name}`}>{campaign.name}</a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PageWrap>
  );
};

export default SignupCustomPageDebug;
